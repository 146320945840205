import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { deleteNote } from "../../../../../store/actions/note";
import { formatDate } from "../../../../../utils/formatDate";
import "../style.css";

export const NotesItem = ({ note }) => {
  const dispatch = useDispatch();

  const handleDeleteNote = async (id) => {
    try {
      dispatch(deleteNote(id));
    } catch (err) {
      //console.log("Delete project", err);
    }
  };

  return (
    <Box className="note_container">
      <Box className="note_wrapper">
        <Box className="note_owner_avatar">
          <Avatar />
        </Box>
        <Box className="note_info">
          <Box className="note_info_header">
            <Typography variant="h6" className="note_owner">
              {note.data.owner.data.name}
            </Typography>
            <Typography variant="caption" className="note_createdAt">
              {formatDate(note.created)}
            </Typography>
          </Box>
          <Typography variant="body2">{note.data.name}</Typography>
        </Box>
      </Box>
      {/* <Box className="note_action">
        <IconButton
          color="primary"
          sx={{ p: "10px", color: "#EB5757" }}
          aria-label="directions"
          onClick={() => handleDeleteNote(note._id)}
          disabled={disabled}
        >
          <DeleteForeverOutlinedIcon />
        </IconButton>
      </Box> */}
    </Box>
  );
};
