import {
  SET_USERS,
  FETCH_USERS_END,
  FETCH_USERS_START,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";

import { AppConfig } from "../../../config";

export function getUsers(params = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_USERS_START,
      });

      const data = await api.users.getUsers(params);

      dispatch({
        type: SET_USERS,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_USERS_END,
      });
    }
  };
}

export function deleteUser(id) {
  return async (dispatch) => {
    try {
      await fetch(`${AppConfig.apiUrl}/user/submission/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      //console.log(error);
    }
  };
}
