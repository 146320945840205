import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { EditDocument } from "../../Icons";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { FindingModal } from "../../Modals/FindingModal";
import { ShowNotesModal } from "../../Modals/ShowNotesModal";
import { renderTableStatus } from "../utils";
import { deleteFinding } from "../../../store/actions/finding";
import { formatDate } from "../../../utils/formatDate";
import { createNote } from "../../../store/actions/note";
import "../general_css/style.css";
import "./style.css";

export function FindingGridTable({ findings, projectData }) {
  const dispatch = useDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [findingId, setFindingId] = useState(false);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [selectedFinding, setSelectedFinding] = useState(null);
  const [notesData, setNotesData] = useState({});
  const modalDescription = `${selectedFinding?.data?.name}`;
  const dumpData = findings?.map((finding) => {
    return {
      ...finding,
      id: finding._id,
    };
  });

  const handleDeleteFinding = async (id) => {
    try {
      dispatch(deleteFinding(id));
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEditModal = (id) => {
    setFindingId(id);
    setIsOpenEditModal(true);
  };

  const handleCloseFindingModal = () => {
    setIsOpenEditModal(false);
  };

  const handleOpenNoteModal = (data) => {
    setIsOpenNoteModal(true);
    setSelectedFinding(data);
  };

  const handleCloseNoteModal = () => {
    setIsOpenNoteModal(false);
    setSelectedFinding(null);
    setNotesData({});
  };

  const handleChangeNotes = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNotesData({
      [name]: value,
      companyProject: {
        _id: projectData._id,
      },
      finding: {
        _id: selectedFinding._id,
      },
    });
  };

  const handleSubmitNotes = async () => {
    try {
      await dispatch(createNote(notesData));
      setIsOpenNoteModal(false);
      setNotesData({});
    } catch (error) {
      console.log(error);
    }
  };

  const projectColumns = [
    {
      // Necessary for edit/delete
      field: "_id",
      headerName: "Id",
      hide: true,
    },
    {
      field: "data.findingNumber",
      headerName: "Item #",
      sortable: true,
      flex: 1,
      valueGetter: (params) => {
        return params.row.data.findingNumber;
      },
      sortComparator: (a, b) => a - b,
    },
    {
      field: "name",
      headerName: "Topic",
      sortable: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="header_column">{params.row.data.name || "-"}</div>
        );
      },
    },
    {
      field: "data.status",
      headerName: "Status",
      sortable: true,
      flex: 2,
      renderCell: (params) => {
        const status = params.row.data.status;
        return renderTableStatus(status, "FINDING");
      },
    },
    {
      field: "assigned",
      headerName: "Assigned To",
      sortable: true,
      flex: 2,
      valueGetter: (params) => {
        return params.row.data.assignedTo.data.name || "-";
      },
    },
    {
      field: "date",
      headerName: "Due Date",
      sortable: true,
      flex: 2,
      valueGetter: (params) => {
        return formatDate(params.row.data.dueDate) || "-";
      },
    },
    {
      field: "round",
      headerName: "Round",
      sortable: true,
      flex: 1,
      valueGetter: (params) => {
        return params.row.data.round || "--";
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              aria-label="edit"
              sx={{ mr: 1 }}
              onClick={() => handleOpenEditModal(params.row._id)}
            >
              <EditDocument />
            </IconButton>
            <IconButton
              aria-label="edit"
              sx={{ mr: 1 }}
              onClick={() => handleOpenNoteModal(params.row)}
            >
              <ArticleOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{ p: "10px", color: "#EB5757" }}
              aria-label="directions"
              onClick={() => handleDeleteFinding(params.row._id)}
            >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        columns={projectColumns}
        rows={dumpData}
        style={{ height: "inherit" }}
        hideFooter
        autoHeight
      />
      {isOpenEditModal && (
        <FindingModal
          title="Edit Item"
          description="Update item details here"
          mode="EDIT"
          findingId={findingId}
          onClose={handleCloseFindingModal}
        />
      )}
      {isOpenNoteModal && (
        <ShowNotesModal
          onClose={handleCloseNoteModal}
          title="Notes"
          topic="Item"
          description={modalDescription}
          notes={selectedFinding.notes}
          value={notesData.name}
          onChange={handleChangeNotes}
          onSubmit={handleSubmitNotes}
          showAddNotesInput={true}
        />
      )}
    </>
  );
}
