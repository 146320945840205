import React, { useState } from "react";
import { toast } from "react-toastify";
import "formiojs/dist/formio.builder.min.css";
import { Form } from "@formio/react";
import { Auth } from "../../../modules/auth";
import axios from "axios";
import { useConstructor } from "../../SimpleDisplayGrid";

export const FormRenderer = ({ url, onSubmit, customSubmission, children }) => {
  const auth = Auth();
  const [webForm, setWebForm] = useState({
    form: undefined,
    submission: undefined,
  });
  const formRef = React.useRef(null);

  useConstructor(() => {
    axios.get(url, { headers: auth.getAuthHeaders() }).then(
      (response) => {
        let _data = response.data;
        _data.components.forEach((component) => {
          if (component.type === "select") {
            component.data = {
              ...component.data,
              headers: auth.getAuthHeadersKV(),
            };
          }
          if (component.type === "columns") {
            (component.columns || []).forEach((column) => {
              (column.components || []).forEach((columnComponent) => {
                if (columnComponent.type === "select") {
                  columnComponent.data = {
                    ...columnComponent.data,
                    headers: auth.getAuthHeadersKV(),
                  };
                }
              });
            });
          }
        });
        setWebForm({ form: _data, submission: _data.submission });
        if (customSubmission !== undefined) {
          setWebForm({ form: _data, submission: customSubmission });
        }
      },
      (e) => toast.error(`${e.response.data.message}`)
    );
  });

  const onFormReady = React.useCallback((form) => {
    formRef.current = form;
  }, []);

  const _onSubmit = React.useCallback(
    (submission) => {
      if (onSubmit !== undefined) onSubmit(submission, formRef);
    },
    [onSubmit]
  );

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        {webForm.submission === undefined ? (
          <Form
            src={webForm.form}
            onSubmit={_onSubmit}
            formReady={onFormReady}
          />
        ) : (
          <Form
            src={webForm.form}
            onSubmit={_onSubmit}
            submission={webForm.submission}
            formReady={onFormReady}
            options={{ submitOnEnter: true }}
          />
        )}
      </div>
    </div>
  );
};
