import React from "react";

const FindingIcon = (props) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="45" height="45" rx="12" fill="#2F80ED" fill-opacity="0.1" />
    <path
      d="M32 12.5H13V32.5H32V12.5Z"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.7777 16.1362H17.2222V21.5908H27.7777V16.1362Z"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.2222 25.2271H27.7777"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.2222 28.8638H27.7777"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default FindingIcon;
