import {
  FETCH_FINDINGS_START,
  FETCH_FINDINGS_END,
  SET_FINDINGS,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";

export function getFindings(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_FINDINGS_START,
      });

      const data = await api.findings.getFindings(params);

      dispatch({
        type: SET_FINDINGS,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_FINDINGS_END,
      });
    }
  };
}

export function deleteFinding(id) {
  return async (dispatch) => {
    try {
      await api.findings.deleteFinding(id);
      await dispatch(getDashboardData());
    } catch (error) {
      //console.log("Delete finding", error);
    }
  };
}

export function createFinding(data) {
  return async (dispatch) => {
    try {
      await api.findings.createFinding(data);
      dispatch(getDashboardData());
    } catch (error) {
      //console.log("Delete finding", error);
    }
  };
}

export function updateFinding(data, id) {
  return async (dispatch) => {
    try {
      await api.findings.updateFinding(data, id);
      dispatch(getDashboardData());
    } catch (error) {
      //console.log("Delete finding", error);
    }
  };
}
