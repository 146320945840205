import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { MainMenu } from "../../components/Menu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PopperModal } from "../../components/Modals/PopperModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

import defaultLogo from "../../assets/icons/Logo.png";
import { sessionSelector } from "../../store/selectors/session";
import { Auth } from "../../modules/auth";

export const MainLayout = () => {
  const navigate = useNavigate();
  const [islogout, setIsLogout] = useState(false);
  const auth = Auth();
  const userData = useSelector(sessionSelector);
  const companyLogoUrl = userData?.company?.data?.logo;

  const handleLogout = async () => {
    try {
      auth.logOut();
      if (auth.isAuthenticated() === "false") {
        // localStorage.clear(); // intentionally commented out
        navigate("/");
      }
    } catch (error) {
      //console.log("Logout error", error);
    }
  };

  const openLogoutModal = () => {
    setIsLogout(true);
  };

  const closeLogoutModal = () => {
    setIsLogout(false);
  };

  return (
    <>
      <Grid container columns={16} className="main_layout">
        <Grid item className="sider">
          <Grid
            container
            direction={"column"}
            style={{
              justifyContent: "space-between",
              paddingTop: "8px",
              height: "100vh",
              position: "fixed",
              width: "64px",
              overflow: "auto",
            }}
          >
            <Grid
              container
              style={{
                height: "100%",
              }}
            >
              <div className="logo">
                <Avatar
                  alt="Logo"
                  src={companyLogoUrl ?? defaultLogo}
                  sx={{ width: 48, height: 48 }}
                />
              </div>
              <div className="menu">
                <MainMenu />
              </div>

              <div className="avatar">
                <Tooltip title="Log out" placement="right">
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt="user"
                    onClick={openLogoutModal}
                  />
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="main_wrapper">
          <Outlet />
        </Grid>
        <ToastContainer theme="colored" />
      </Grid>
      {islogout && (
        <PopperModal
          onClose={closeLogoutModal}
          onSubmit={handleLogout}
          title="Do you really want to log out?"
          okBtnProps={{ label: "YES" }}
        />
      )}
    </>
  );
};
