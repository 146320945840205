import React from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalFooter,
  ModalHeader,
} from "../../ui-components/ModalConstructor";

export const PopperModal = ({
  onClose,
  onSubmit,
  title,
  divider = false,
  okBtnProps,
}) => {
  return (
    <ModalContainer onClose={onClose}>
      <ModalHeader title={title} closeButton={false} divider={divider} />
      <ModalFooter
        buttons={[
          {
            label: "Cancel",
            isLoading: false,
            visible: true,
            onClick: onClose,
            btnStyle: "white",
          },
          {
            label: "Delete",
            visible: true,
            onClick: onSubmit,
            btnStyle: "black",
            ...okBtnProps,
          },
        ]}
      />
    </ModalContainer>
  );
};

PopperModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
};
PopperModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: "",
};
