import React from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import "./style.css";

export const AddLinkModal = ({
  onClose,
  onSubmit,
  title,
  value,
  description,
  topic,
  onChange,
  width = "500px",
  isLoading = false,
  cancelButtonProps = {},
  submitButtonProps = {},
  ref,
}) => {
  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        type="LINK"
        topic={topic}
      />
      <ModalContent>
        <InputLabel htmlFor="url" className="inputLabel">
          URL
        </InputLabel>
        <div
          style={{
            width: "100%",
            height: "fit-content",
            border: "1px solid #BDBDBD",
            borderRadius: "4px",
            padding: "8px 10px",
            marginBottom: "41px",
          }}
        >
          <TextField
            value={value.url}
            id="url"
            onChange={onChange}
            name="url"
            sx={{ border: "none", width: "100%" }}
            multiline
            variant="standard"
            placeholder="http://"
            style={{}}
            className="notes"
          />
        </div>
        <InputLabel htmlFor="url" className="inputLabel">
          Link Text
        </InputLabel>
        <div
          style={{
            width: "100%",
            height: "fit-content",
            border: "1px solid #BDBDBD",
            borderRadius: "4px",
            padding: "8px 10px",
          }}
        >
          <TextField
            value={value.linkText}
            onChange={onChange}
            name="linkText"
            sx={{ border: "none", width: "100%" }}
            multiline
            variant="standard"
            placeholder="Enter"
            style={{}}
            className="notes"
          />
        </div>
      </ModalContent>
      <ModalFooter
        buttons={[
          {
            label: "Cancel",
            isLoading: false,
            visible: true,
            onClick: onClose,
            btnStyle: "white",
            ...cancelButtonProps,
          },
          {
            label: "Add Link",
            isLoading,
            visible: true,
            onClick: onSubmit,
            btnStyle: "black",
            ...submitButtonProps,
          },
        ]}
      />
    </ModalContainer>
  );
};

AddLinkModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
};
AddLinkModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: "",
  description: "",
  isLoading: false,
};
