import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import orderBy from "lodash/orderBy";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Export } from "../../Icons";
import { CustomButton } from "../../ui-components/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import NumberFormat from "react-number-format";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Paper from "@mui/material/Paper";

import { getMonthRange } from "../../../utils/formatDate";
import {
  createExpenses,
  updateExpenses,
  getExpenses,
} from "../../../store/actions/expenses";
import { loadExpenses } from "../../../store/selectors/expenses";
import "./style.css";
import { exportExpense } from "../../../store/actions/project";

export default function BudgetGridTable({
  projectStatus,
  budget = {},
  isEditBudget,
}) {
  const dispatch = useDispatch();
  const {
    documents = [],
    findings = [],
    company = {},
    project = {},
    totalExpensesForDocuments,
  } = budget;

  const companyName = company?.data?.name;
  const companyLogoURL = company?.data?.companyLogoURL;
  const companyId = company?._id;
  const projectId = project?._id;
  const startDate = project?.data?.startDate;
  const projectName = project?.data?.name;
  const [isEditTable, setIsEditTable] = useState(false);
  const [documentData, setDocumentData] = useState([]);
  const [monthColumns, setMonthColumn] = useState([]);
  const [budgetData, setBudgetData] = useState([]);
  const [columnTosort, setColumnTosort] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const findingStatuses = ["Responding to Agency", "Agency Review"];
  const getFieldName = (status) => {
    const documetField =
      status === "In Preparation for Submission" ? "document" : "finding";
    const idField =
      status === "In Preparation for Submission"
        ? "documentId"
        : "findingNumber";
    return { documetField, idField };
  };

  useEffect(() => {
    const { documetField, idField } = getFieldName(projectStatus);

    const data = documentData?.map((doc) => {
      return {
        budget: doc?.expenses?.data?.budget,
        pricePerMonths: doc?.expenses?.data?.pricePerMonths,
        progress: doc?.expenses?.data?.progress,
        totalToDate: doc?.expenses?.data?.totalToDate,
        [idField]: doc?.data?.documentId || doc?.data?.findingNumber,
        name: doc?.data?.name,
        id: doc._id,
        [documetField]: {
          _id: doc._id,
        },
        expensesId: doc?.expenses?._id,
      };
    });
    setBudgetData(data);
  }, [documentData, budget]);

  const availableMonth = useMemo(() => {
    return getMonthRange(startDate);
  }, [startDate]);

  const loading = useSelector(loadExpenses);

  useEffect(() => {
    if (projectStatus === "In Preparation for Submission") {
      setDocumentData(documents);
    } else if (findingStatuses.includes(projectStatus)) {
      setDocumentData(findings);
    }
  }, [budget]);

  const handleChangeBudget = (field, id, value) => {
    const newData = budgetData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [field]: +value,
        };
      } else return item;
    });
    setBudgetData(newData);
  };

  const handleChangePricePermonth = (field, id, value) => {
    const newData = budgetData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          pricePerMonths: {
            ...item.pricePerMonths,
            [field]: +value,
          },
        };
      } else return item;
    });
    setBudgetData(newData);
  };

  useEffect(() => {
    const data = availableMonth?.map((month) => {
      const formatMonth = moment(month, "MMM YY").format("YYYY-MM");
      return {
        headerName: month,
        field: formatMonth,
        fieldToSort: `pricePerMonths.${formatMonth}`,
      };
    });
    setMonthColumn(data);
  }, [isEditTable, availableMonth, budgetData]);

  const editTable = () => {
    setIsEditTable(true);
  };

  const handleSubmit = async () => {
    const { documetField } = getFieldName(projectStatus);
    const data = budgetData
      .filter((item) => item.budget)
      .map((doc) => {
        const { budget, pricePerMonths, expensesId } = doc;

        if (!expensesId) {
          return {
            budget,
            [documetField]: doc[documetField],
            pricePerMonths,
          };
        }
        if (expensesId) {
          return {
            _id: expensesId,
            budget,
            pricePerMonths,
          };
        }
      });

    const createdData = data.filter((item) => !item._id);
    const updatedData = data.filter((item) => !!item._id);

    const submitedDataForCreate = {
      companyProject: {
        _id: projectId,
      },
      company: {
        _id: companyId,
      },
      costExpenses: [...createdData],
    };

    const submitedDataForUpdate = {
      companyProject: {
        _id: projectId,
      },
      company: {
        _id: companyId,
      },
      costExpenses: [...updatedData],
    };
    try {
      if (createdData.length) {
        dispatch(createExpenses({ data: submitedDataForCreate }));
        dispatch(getExpenses(projectId));
      }
      if (updatedData.length) {
        dispatch(updateExpenses({ data: submitedDataForUpdate }));
        dispatch(getExpenses(projectId));
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(getExpenses(projectId));
    }

    setIsEditTable(false);
  };

  const numberFormat = (numder) => {
    if (!numder) {
      return;
    }
    return new Intl.NumberFormat("en-IN").format(numder);
  };

  const invertDirection = {
    asc: "desc",
    desc: "asc",
  };

  const handleSort = (columnName) => {
    setColumnTosort(columnName);
    const curentSortDirection =
      columnTosort === columnName ? invertDirection[sortDirection] : "asc";
    setSortDirection(curentSortDirection);
    const data = orderBy(budgetData, columnTosort, sortDirection);
    setBudgetData(data);
  };

  const handleExportExenses = async () => {
    try {
      dispatch(exportExpense(project));
    } catch (err) {
      console.log(err);
    }
  };

  const inputRef = React.createRef();

  return (
    <div style={{ height: 400, width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "30px",
            }}
          >
            <div className="header_logo">
              <Avatar src={companyLogoURL} />
            </div>
            <div>
              <span className="header_title">Organization</span>
              <p className="header_value">{companyName}</p>
            </div>
          </div>
          <div>
            <span className="header_title">Project</span>
            <p className="header_value">{projectName}</p>
          </div>
        </div>
        {isEditBudget && (
          <div
            style={{
              width: "35%",
              display: "flex",
              justifyContent: isEditTable ? "end" : "space-around",
              flexWrap: "wrap",
            }}
          >
            {isEditTable ? (
              <CustomButton
                startIcon={<CheckOutlinedIcon />}
                title="Save Changes"
                onClick={handleSubmit}
              />
            ) : (
              <>
                <CustomButton
                  startIcon={<Export />}
                  title="Export Excel Sheet"
                  onClick={handleExportExenses}
                />
                <CustomButton
                  startIcon={<EditOutlinedIcon />}
                  title="Edit Table"
                  onClick={editTable}
                />
              </>
            )}
          </div>
        )}
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: 340 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              minWidth: "max-content",
              // overflow: "hiden",
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  "td, th": {
                    background: "#F6F7F9",
                    color: "#646464",
                    fontSize: "14px",
                    fontWeight: 700,
                    cursor: "pointer",
                  },
                }}
              >
                {projectStatus === "In Preparation for Submission" && (
                  <TableCell
                    align="left"
                    width={150}
                    style={{
                      position: "-webkit-sticky",
                      position: "sticky",
                      left: 0,
                      top: 0,
                      zIndex: 100,
                    }}
                    onClick={() => handleSort("documentId")}
                  >
                    Doc ID
                    {"documentId" === columnTosort ? (
                      sortDirection === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : null}
                  </TableCell>
                )}

                {findingStatuses.includes(projectStatus) && (
                  <TableCell
                    align="left"
                    width={150}
                    onClick={() => handleSort("findingNumber")}
                    style={{
                      position: "-webkit-sticky",
                      position: "sticky",
                      left: 0,
                      zIndex: 100,
                    }}
                  >
                    Doc ID
                    {"findingNumber" === columnTosort ? (
                      sortDirection === "asc" ? (
                        <ArrowUpwardIcon />
                      ) : (
                        <ArrowDownwardIcon />
                      )
                    ) : null}
                  </TableCell>
                )}
                <TableCell
                  align="left"
                  width={200}
                  onClick={() => handleSort("name")}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 150,
                    zIndex: 100,
                  }}
                >
                  Item Name
                  {"name" === columnTosort ? (
                    sortDirection === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  ) : null}
                </TableCell>
                <TableCell
                  align="left"
                  width={150}
                  onClick={() => handleSort("budget")}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 350,
                    zIndex: 100,
                  }}
                >
                  Budget
                  {"budget" === columnTosort ? (
                    sortDirection === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  ) : null}
                </TableCell>
                <TableCell
                  align="left"
                  width={150}
                  onClick={() => handleSort("totalToDate")}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 500,
                    zIndex: 100,
                  }}
                >
                  Total to Date
                  {"totalToDate" === columnTosort ? (
                    sortDirection === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  ) : null}
                </TableCell>
                <TableCell
                  align="left"
                  width={150}
                  onClick={() => handleSort("progress")}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 650,
                    zIndex: 100,
                  }}
                >
                  Progress
                  {"progress" === columnTosort ? (
                    sortDirection === "asc" ? (
                      <ArrowUpwardIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  ) : null}
                </TableCell>

                {monthColumns.map((column) => {
                  return (
                    <TableCell
                      align="left"
                      width={150}
                      onClick={() => handleSort(column.fieldToSort)}
                      key={column.field}
                    >
                      {column.headerName}
                      {column.fieldToSort === columnTosort ? (
                        sortDirection === "asc" ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetData?.map((row) => {
                const budget = numberFormat(row.budget);
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      "td, th": {
                        color: "#333333",
                        fontSize: "14px",
                        fontWeight: 400,
                        background: "#FFFFFF",
                      },
                    }}
                  >
                    <TableCell
                      width={150}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        background: "#fff",
                        left: 0,
                        zIndex: 10,
                      }}
                    >
                      {projectStatus === "In Preparation for Submission"
                        ? row?.documentId
                        : row.findingNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      width={200}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        left: 150,
                        zIndex: 10,
                      }}
                    >
                      {row?.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      width={150}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        left: 350,
                        zIndex: 10,
                      }}
                    >
                      <NumberFormat
                        getInputRef={inputRef}
                        name="budget"
                        id="budget"
                        value={budget}
                        onValueChange={(values, sourceInfo) => {
                          const { value } = values;
                          handleChangeBudget("budget", row.id, value);
                        }}
                        disabled={!isEditTable}
                        placeholder={isEditTable ? "Enter" : "-"}
                        customInput={OutlinedInput}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                        className={classNames({
                          budget_cell: true,
                          disabled_input: true,
                          active_Input: isEditTable,
                        })}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      width={150}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        left: 500,
                        zIndex: 10,
                      }}
                    >
                      <NumberFormat
                        getInputRef={inputRef}
                        value={row?.totalToDate}
                        disabled={true}
                        placeholder={"-"}
                        customInput={OutlinedInput}
                        thousandSeparator={true}
                        prefix={"$"}
                        className={classNames({
                          budget_cell: true,
                          disabled_input: true,
                        })}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      width={150}
                      style={{
                        position: "-webkit-sticky",
                        position: "sticky",
                        left: 650,
                        zIndex: 10,
                        overflow: "hidden",
                      }}
                    >
                      <NumberFormat
                        getInputRef={inputRef}
                        value={row?.progress?.toFixed(2)}
                        disabled={true}
                        placeholder={"-"}
                        customInput={OutlinedInput}
                        thousandSeparator={true}
                        suffix={"%"}
                        className={classNames({
                          budget_cell: true,
                          disabled_input: true,
                        })}
                      />
                    </TableCell>
                    {monthColumns?.map((month) => {
                      const field = month?.field;
                      const value = row?.pricePerMonths?.[field];
                      return (
                        <TableCell align="left" width={150} key={month.field}>
                          <NumberFormat
                            getInputRef={inputRef}
                            value={value}
                            onValueChange={(values, sourceInfo) => {
                              const { value } = values;
                              handleChangePricePermonth(field, row.id, value);
                            }}
                            disabled={!isEditTable}
                            placeholder={isEditTable ? "Enter" : "-"}
                            customInput={OutlinedInput}
                            thousandSeparator={true}
                            decimalScale={2}
                            style={{
                              zIndex: 1,
                            }}
                            prefix={"$"}
                            className={classNames({
                              budget_cell: true,
                              disabled_input: true,
                              active_Input: isEditTable,
                            })}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow
                sx={{
                  "td, th": {
                    color: "#333333",
                    fontSize: "14px",
                    fontWeight: 700,
                    background: "#F6F7F9",
                  },
                }}
              >
                <TableCell
                  align="left"
                  width={150}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  Totals
                </TableCell>
                <TableCell
                  align="left"
                  width={200}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 150,
                    zIndex: 10,
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  width={150}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 350,
                    zIndex: 10,
                  }}
                >
                  <NumberFormat
                    getInputRef={inputRef}
                    value={totalExpensesForDocuments?.totalBudget}
                    disabled={true}
                    placeholder={isEditTable ? "Enter" : "-"}
                    customInput={OutlinedInput}
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={"$"}
                    className={classNames({
                      budget_cell: true,
                      disabled_input: true,
                      budget_cell_bold: true,
                    })}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  width={150}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 500,
                    zIndex: 10,
                  }}
                >
                  <NumberFormat
                    getInputRef={inputRef}
                    value={totalExpensesForDocuments?.totalToDates}
                    disabled={true}
                    placeholder={isEditTable ? "Enter" : "-"}
                    customInput={OutlinedInput}
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={"$"}
                    className={classNames({
                      budget_cell: true,
                      disabled_input: true,
                      budget_cell_bold: true,
                    })}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  width={150}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    left: 650,
                    zIndex: 10,
                  }}
                >
                  <NumberFormat
                    getInputRef={inputRef}
                    value={totalExpensesForDocuments?.totalProgress?.toFixed(2)}
                    disabled={true}
                    decimalScale={2}
                    placeholder={isEditTable ? "Enter" : "-"}
                    customInput={OutlinedInput}
                    thousandSeparator={true}
                    suffix={"%"}
                    className={classNames({
                      budget_cell: true,
                      disabled_input: true,
                      budget_cell_bold: true,
                    })}
                  />
                </TableCell>
                {monthColumns?.map((month) => {
                  const field = month?.field;
                  return (
                    <TableCell key={month.field} width={150}>
                      <NumberFormat
                        getInputRef={inputRef}
                        value={
                          totalExpensesForDocuments?.totalPerMonths?.[field]
                        }
                        disabled={true}
                        placeholder={isEditTable ? "Enter" : "-"}
                        customInput={OutlinedInput}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={"$"}
                        className={classNames({
                          budget_cell: true,
                          disabled_input: true,
                          budget_cell_bold: true,
                        })}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
