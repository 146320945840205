import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router";
import "formiojs/dist/formio.builder.min.css";
import "./login.css";
import { AppConfig } from "../../../config";
import { Auth } from "../../../modules/auth";
import { FormRenderer } from "../FormRender";
import { errorResponseProcessor } from "../../../utils/responseProcessor";
import axios from "axios";
import { useConstructor } from "../../../components/SimpleDisplayGrid";

export const LoginForm = () => {
  const navigate = useNavigate();
  const auth = Auth();
  const [defaultValues, setDefaultValues] = useState({});

  useConstructor(() => {
    setDefaultValues({
      data: {
        email: localStorage.getItem("email"),
        password: "",
        rememberMe: false,
        submit: false,
      },
    });
  });

  useEffect(() => {
    if (auth.isAuthenticated() === "true") {
      navigate(ROUTES.DASHBOARD);
    }
  }, [auth, navigate]);

  const onSubmit = (submission, form) => {
    form.current.checkData();
    axios
      .post(AppConfig.apiUrl + "/auth/login/submission", submission)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          if (submission.data.rememberMe === true) {
            localStorage.setItem("rememberMe", submission.data.rememberMe);
            localStorage.setItem(
              "email",
              submission.data.rememberMe ? submission.data.email : ""
            );
          }
          auth.setUser(response.data);
          if (auth.isAuthenticated() === "true") {
            navigate(ROUTES.DASHBOARD);
          }
        }
      })
      .catch((error) => {
        errorResponseProcessor(error, form);
      });
  };

  return (
    <div className="registration_form">
      <div className="login_header">
        <h2>Login</h2>
        <p>Enter your credentials below to login</p>
      </div>
      <FormRenderer
        url={AppConfig.apiUrl + "/auth/login"}
        onSubmit={onSubmit}
        customSubmission={defaultValues}
      />
    </div>
  );
};
