export const NOTIFICATIONS = {
  note: {
    create: {
      success: { message: "Note create success" },
      error: { message: "There was an error creating a note" },
    },
    delete: {
      success: { message: "Note delete success" },
      error: { message: "There was an error deleting a note" },
    },
    update: {
      success: { message: "Note was updated" },
      error: { message: "There was an error updating a Note" },
    },
  },
  document: {
    create: {
      success: { message: "Document create success" },
      error: { message: "There was an error creating a document" },
    },
    delete: {
      success: { message: "Document delete success" },
      error: { message: "There was an error deleting a site" },
    },
    update: {
      success: { message: "Document was updated" },
      error: { message: "There was an error updating a document" },
    },
    upload: {
      success: { message: "Document was uploaded" },
      error: { message: "An error occurred while uploading the document" },
    },
  },
  organization: {
    create: {
      success: { message: "Organization add success" },
      error: { message: "There was an error creating a organization" },
    },
    delete: {
      success: { message: "Organization delete success" },
      error: { message: "There was an error deleting a site" },
    },
    update: {
      success: { message: "Organization was updated" },
      error: { message: "There was an error updating a Organization" },
    },
  },
};
