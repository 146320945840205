import React from "react";

const BudgetIcon = (props) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="45" height="45" rx="12" fill="#2F80ED" fill-opacity="0.1" />
    <path
      d="M17.3182 14.7272H14.7273C13.773 14.7272 13 15.5001 13 16.4544C13 17.4088 13.773 18.1817 14.7273 18.1817"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.3181 18.1818V12.9999H29.409V18.1818"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32 18.1819H14.7273C13.773 18.1819 13 17.4089 13 16.4546V29.4091C13 30.8402 14.1599 32 15.5909 32H32V18.1819Z"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.8181 26.8182C27.772 26.8182 28.5454 26.0449 28.5454 25.0909C28.5454 24.137 27.772 23.3636 26.8181 23.3636C25.8641 23.3636 25.0908 24.137 25.0908 25.0909C25.0908 26.0449 25.8641 26.8182 26.8181 26.8182Z"
      stroke="#2F80ED"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default BudgetIcon;
