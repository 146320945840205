import React from "react";
import { Link } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  AgencyIcon,
  CompanyMenagementIcon,
  DashboardIcon,
  ProjectMenagementIcon,
  UserMenagementIcon,
} from "../Icons";
import "./style.css";
import Tooltip from "@mui/material/Tooltip";
import { Auth } from "../../modules/auth";
import { checkPermissions } from "../../utils/checkPermissions";

export const MainMenu = () => {
  const auth = Auth();

  return (
    <MenuList variant="menu">
      <MenuItem component={Link} to="/dashboard">
        <Tooltip title="Dashboard" placement="right">
          <ListItemIcon>
            <DashboardIcon
              style={{
                height: "48px",
                width: "100%",
                paddingLeft: "8px",
                paddingTop: "15px",
              }}
            />
          </ListItemIcon>
        </Tooltip>
      </MenuItem>
      {checkPermissions("superAdmin") && (
        <MenuItem component={Link} to="/dashboard/organization-management">
          <Tooltip title="Organization Management" placement="right">
            <ListItemIcon>
              <CompanyMenagementIcon
                style={{
                  height: "48px",
                  width: "100%",
                  paddingLeft: "8px",
                  paddingTop: "15px",
                }}
              />
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      )}
      {!checkPermissions("regularUser") && (
        <MenuItem component={Link} to="/dashboard/project-management">
          <Tooltip title="Project Management" placement="right">
            <ListItemIcon>
              <ProjectMenagementIcon
                style={{
                  height: "48px",
                  width: "100%",
                  paddingLeft: "8px",
                  paddingTop: "15px",
                }}
              />
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      )}
      {/* <MenuItem component={Link} to="/dashboard/document-management">
        <Tooltip title="Document Management" placement="right">
          <ListItemIcon>
            <DocumentIcon
              style={{
                marginLeft: "8px",
                height: "48px",
                paddingTop: "15px",
              }}
            />
          </ListItemIcon>
        </Tooltip>
      </MenuItem>
      <MenuItem component={Link} to="/dashboard/finding-management">
        <Tooltip title="Communication Management" placement="right">
          <ListItemIcon>
            <DocumentIcon
              style={{
                marginLeft: "8px",
                height: "48px",
                paddingTop: "15px",
              }}
            />
          </ListItemIcon>
        </Tooltip>
      </MenuItem> */}
      <MenuItem component={Link} to="/dashboard/user-management">
        <Tooltip title="User Management" placement="right">
          <ListItemIcon>
            <UserMenagementIcon
              style={{
                height: "48px",
                width: "100%",
                paddingLeft: "8px",
                paddingTop: "15px",
              }}
            />
          </ListItemIcon>
        </Tooltip>
      </MenuItem>
      {checkPermissions("superAdmin") && (
        <MenuItem component={Link} to="/dashboard/admin-management">
          <Tooltip title="Admin Management" placement="right">
            <ListItemIcon>
              <UserMenagementIcon
                style={{
                  height: "48px",
                  width: "100%",
                  paddingLeft: "8px",
                  paddingTop: "15px",
                }}
              />
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      )}
      {checkPermissions("superAdmin") && (
        <MenuItem component={Link} to="/dashboard/agency">
          <Tooltip title="Agency Management" placement="right">
            <ListItemIcon>
              <AgencyIcon
                style={{
                  height: "48px",
                  width: "100%",
                  paddingLeft: "8px",
                  paddingTop: "15px",
                }}
              />
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      )}
    </MenuList>
  );
};
