import React from "react";

import { AgencyTable } from "../../components/Table/AgencyTable";
import { AgencyTemplateTable } from "../../components/Table/AgencyTemplateTable";
import { DocumentTemplateTable } from "../../components/Table/DocumentTemplateTable";
import { TabLayout } from "../../components/TabLayout";
import { Importer } from "../../components/Importer";
import { ImporterType } from "../../constants/importer";

export const AgencyLayout = () => {
  return (
    <TabLayout style={{ marginLeft: "20px" }}>
      <AgencyTable pageTitle={"Agencies"} addButtonTitle={"Add Agency"} />
      <AgencyTemplateTable
        pageTitle={"Agency Templates"}
        addButtonTitle={"Add Agency Template"}
      />
      <DocumentTemplateTable
        pageTitle={"Items for Template"}
        addButtonTitle={"Add Item Template"}
      />
      <Importer
        pageTitle={"Items for Template Importer"}
        importerType={ImporterType.DOCUMENT_TEMPLATES}
      />
    </TabLayout>
  );
};
