import React, { useState } from "react";
import queryString from "query-string";
import { Grid } from "@mui/material";
import { FormRenderer } from "../Form/FormRender";
import { AppConfig } from "../../config";
import { Auth } from "../../modules/auth";
import axios from "axios";
import { errorResponseProcessor } from "../../utils/responseProcessor";
import { useConstructor } from "../SimpleDisplayGrid";
import { ImporterType } from "../../constants/importer";
import * as FormData from "form-data";

const uploadFile = (
  auth,
  submission,
  form,
  api_component,
  no_processing = false,
  callback
) => {
  let documents = [];

  if (submission.data.uploadExcelSheet.length > 0) {
    let fileMetadata = submission.data.uploadExcelSheet[0];
    let url = `${
      AppConfig.apiUrl
    }/form/${api_component}/storage/s3?bucket=${encodeURI(
      fileMetadata.bucket
    )}&key=${encodeURI(fileMetadata.key)}`;
    axios.get(url, { headers: auth.getAuthHeaders() }).then((response) => {
      if (response.status === 200) {
        const newURL = response.data.url;

        if (no_processing) {
          axios({ url: newURL, method: "GET", responseType: "blob" }).then(
            (response) => {
              // console.log(response)
              // const url = window.URL.createObjectURL(new Blob([response.data]));
              // const link = document.createElement('a');
              // link.href = url;
              // link.setAttribute('download', `${fileMetadata.originalName}`);
              // document.body.appendChild(link);
              // link.click();

              callback([fileMetadata, new Blob([response.data])]);
            }
          );

          return;
        }

        axios.get(newURL).then((response) => {
          if (response.status === 200) {
            const _documents = response.data.split(",");
            _documents.forEach((document) => {
              documents.push({ name: document });
            });

            if (documents.length === 0) {
              //console.log("No file attached, exiting.");
            }

            if (callback !== undefined) {
              callback(documents);
            }
          }
        });
      }
    });
  }
};

const documentTemplateProcessor = (
  auth,
  submission,
  form,
  api_component,
  callback = () => {}
) => {
  uploadFile(auth, submission, form, api_component, false, (documents) => {
    let newSubmission = {
      data: {
        agency: submission.data.agency,
        agencyTemplate: submission.data.agencyTemplate,
        documents: documents,
      },
    };
    // //console.log(newSubmission)
    axios
      .post(
        `${AppConfig.apiUrl}/import/${api_component}/submission`,
        newSubmission,
        { headers: auth.getAuthHeaders() }
      )
      .then((response) => {
        if (response.status === 201) {
          form.current.setAlert(
            "alert alert-success",
            "Document Template(s) imported!"
          );
          // form.current.emit("error")
          form.current.emit("submitDone");
          callback();
        }
      })
      .catch((error) => {
        //console.log(error.response);
        errorResponseProcessor(error, form);
      });
  });
};

const projectDocumentsProcessor = (
  auth,
  submission,
  form,
  api_component,
  getData,
  callback = () => {}
) => {
  uploadFile(auth, submission, form, api_component, false, (documents) => {
    let newSubmission = {
      data: {
        company: submission.data.company,
        companyProject: submission.data.companyProject,
        documents: documents,
      },
    };

    axios
      .post(
        `${AppConfig.apiUrl}/import/${api_component}/submission`,
        newSubmission,
        { headers: auth.getAuthHeaders() }
      )
      .then((response) => {
        if (response.status === 201) {
          // alert("Document(s) imported!")
          form.current.setAlert("alert alert-success", "Document(s) imported!");
          // form.current.emit("error")
          form.current.emit("submitDone");
          getData();
          callback();
        }
      })
      .catch((error) => {
        //console.log(error.response);
        errorResponseProcessor(error, form);
      });
  });
};

const documentFindingsProcessor = (
  auth,
  submission,
  form,
  api_component,
  getData,
  callback = () => {}
) => {
  uploadFile(auth, submission, form, api_component, true, (response) => {
    const formData = new FormData();
    formData.append("file", response[1], response[0].originalName);
    axios
      .post(`${AppConfig.apiUrl}/import/${api_component}/parse`, formData, {
        headers: {
          ...auth.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 201) {
          let documents = [];
          response.data.forEach((item) => {
            for (const [key, value] of Object.entries(item.rounds)) {
              let closed = value.closed;
              if (!closed && item.responses[key] != undefined) {
                closed = item.responses[key].closed;
              }
              documents.push({
                name: value.description,
                findingNumber: item.number,
                round: key,
                closed: closed,
              });
            }
          });
          let newSubmission = {
            data: {
              company: submission.data.company,
              companyProject: submission.data.companyProject,
              findings: documents,
            },
          };

          axios
            .post(
              `${AppConfig.apiUrl}/import/${api_component}/submission`,
              newSubmission,
              { headers: { ...auth.getAuthHeaders() } }
            )
            .then((response) => {
              form.current.setAlert(
                "alert alert-success",
                "Document imported!, please review imported items to ensure that they are correctly imported."
              );
              form.current.emit("submitDone");
              getData();
              callback();
            })
            .catch((error) => {
              errorResponseProcessor(error, form);
            });
        }
      })
      .catch((error) => {
        errorResponseProcessor(error, form);
      });
  });
};

export const Importer = ({
  pageTitle,
  importerType,
  projectId,
  companyId,
  getData = () => {},
  onClose = () => {},
  children,
}) => {
  const auth = Auth();
  const [_api_component, _set_api_component] = useState("");
  const [urlParams, setUrlParams] = useState({});
  const params = {
    companyId,
    projectId,
  };
  const query = `?${queryString.stringify(params)}`;

  useConstructor(() => {
    switch (importerType) {
      case ImporterType.DOCUMENT_TEMPLATES: {
        _set_api_component("doc_templates");
        setUrlParams(`doc_templates`);
        break;
      }
      case ImporterType.PROJECT_DOCUMENTS: {
        _set_api_component("documents");
        setUrlParams(`documents/${query}`);
        break;
      }
      case ImporterType.DOCUMENT_FINDINGS: {
        _set_api_component("findings");
        setUrlParams(`findings/${query}`);
        break;
      }
      default:
        alert("Error: Invalid importer type.");
        break;
    }
  });

  const onSubmit = (submission, form) => {
    form.current.checkData();

    switch (importerType) {
      case ImporterType.DOCUMENT_TEMPLATES: {
        documentTemplateProcessor(
          auth,
          submission,
          form,
          _api_component,
          () => {
            //onClose();
          }
        );
        break;
      }
      case ImporterType.PROJECT_DOCUMENTS: {
        projectDocumentsProcessor(
          auth,
          submission,
          form,
          _api_component,
          getData,
          () => {
            //onClose();
          }
        );
        break;
      }
      case ImporterType.DOCUMENT_FINDINGS: {
        documentFindingsProcessor(
          auth,
          submission,
          form,
          _api_component,
          getData,
          () => {
            //onClose();
          }
        );
        break;
      }
      default:
        alert("Error: Invalid importer type.");
        break;
    }
  };

  return (
    <div
      style={{ margin: "24px 0px 0px 24px", display: "flex", height: "100%" }}
    >
      <div style={{ flexGrow: 1, paddingLeft: "10px" }}>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBottom: "24px",
          }}
        >
          <FormRenderer
            url={AppConfig.apiUrl + `/import/${urlParams}`}
            onSubmit={onSubmit}
          />
        </Grid>
      </div>
    </div>
  );
};
