import api from "../../../apiSingleton";
import { toast } from "react-toastify";
import { getDashboardData } from "../dashboard";
import { NOTIFICATIONS } from "../../../constants/notification";
const { note } = NOTIFICATIONS;

export function createNote(data) {
  return async (dispatch) => {
    try {
      await api.notes.createNote({ data });
      toast.success(`${note.create.success.message}`);
      await dispatch(getDashboardData());
    } catch (error) {
      toast.error(`${note.create.error.message}`);
    }
  };
}

export function deleteNote(id) {
  return async (dispatch) => {
    try {
      await api.notes.deleteNote(id);
      toast.success(`${note.delete.success.message}`);
    } catch (error) {
      console.log(error);
      toast.error(`${note.delete.error}`);
    }
  };
}
