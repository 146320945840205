import { Auth } from "../modules/auth";
import queryString from "query-string";

export default class ApiClient {
  constructor({ prefix = "/" } = {}) {
    this.prefix = prefix;
  }

  get(requestUrl, params) {
    return this.request({
      url: requestUrl,
      method: "GET",
      params,
    });
  }

  post(requestUrl, body = {}, params) {
    return this.request({
      url: requestUrl,
      method: "POST",
      body,
      params,
    });
  }

  put(requestUrl, body = {}, params) {
    return this.request({
      url: requestUrl,
      method: "PUT",
      body,
      params,
    });
  }

  delete(requestUrl, body = {}, params) {
    return this.request({
      url: requestUrl,
      method: "DELETE",
      body,
      params,
    });
  }

  patch(requestUrl, body = {}, params) {
    return this.request({
      url: requestUrl,
      method: "PATCH",
      body,
      params,
    });
  }

  async request({ url, method, body, headers = {}, params = {} }) {
    const auth = Auth();

    const header = await auth.getAuthHeaders();

    const init = {
      method,
      headers: {
        ...header,
        ...headers,
      },
    };

    if (body) {
      init.body = JSON.stringify(body);
    }

    const query = Object.keys(params).length
      ? `?${queryString.stringify(params)}`
      : "";

    try {
      const response = await fetch(`${this.prefix}/${url}${query}`, init);
      if (response.status >= 400) {
        throw new Error("Bad response from server");
      }
      const data = await response.json();

      if (data) {
        return data;
      }
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }
}
