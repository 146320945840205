import React from "react";

const DollarSign = (props) => (
  <svg
    width="12"
    height="18"
    viewBox="0 0 12 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1V17"
      stroke="#4F4F4F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.9375 3.66659C7.6875 2.59139 1.5 2.10819 1.5 5.79993C1.5 9.55993 10.5 7.93859 10.5 12.1999C10.5 15.8127 4.0695 15.6133 1.5 14.3333"
      stroke="#4F4F4F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default DollarSign;
